@media screen and (max-width: 630px) {
  .header-inner {
    height: 60px !important;
  }
}
@media screen and (max-width: 425px) {
  .logo_textt {
    font-size: 16px !important;
  }
  /* .logo-holder .img {
    height: 35px !important;
  } */
}
@media screen and (max-width: 375px) {
  .logo_textt {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 320px) {
  .logo_textt {
    font-size: 14px !important;
  }
  .logo-holder img {
    /* height: 35px !important; */
    width: 200px !important;
  }
}
